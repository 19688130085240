import service from '@/utils/request';

// @Tags RmToolUserMapping
// @Summary 创建RmToolUserMapping
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmToolUserMapping true "创建RmToolUserMapping"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmToolUserMapping/createRmToolUserMapping [post]
export var createRmToolUserMapping = function createRmToolUserMapping(data) {
  return service({
    url: "/rmToolUserMapping/createRmToolUserMapping",
    method: 'post',
    data: data
  });
};

// @Tags RmToolUserMapping
// @Summary 删除RmToolUserMapping
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmToolUserMapping true "删除RmToolUserMapping"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmToolUserMapping/deleteRmToolUserMapping [delete]
export var deleteRmToolUserMapping = function deleteRmToolUserMapping(data) {
  return service({
    url: "/rmToolUserMapping/deleteRmToolUserMapping",
    method: 'delete',
    data: data
  });
};

// @Tags RmToolUserMapping
// @Summary 删除RmToolUserMapping
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除RmToolUserMapping"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmToolUserMapping/deleteRmToolUserMapping [delete]
export var deleteRmToolUserMappingByIds = function deleteRmToolUserMappingByIds(data) {
  return service({
    url: "/rmToolUserMapping/deleteRmToolUserMappingByIds",
    method: 'delete',
    data: data
  });
};

// @Tags RmToolUserMapping
// @Summary 更新RmToolUserMapping
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmToolUserMapping true "更新RmToolUserMapping"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /rmToolUserMapping/updateRmToolUserMapping [put]
export var updateRmToolUserMapping = function updateRmToolUserMapping(data) {
  return service({
    url: "/rmToolUserMapping/updateRmToolUserMapping",
    method: 'put',
    data: data
  });
};

// @Tags RmToolUserMapping
// @Summary 用id查询RmToolUserMapping
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmToolUserMapping true "用id查询RmToolUserMapping"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /rmToolUserMapping/findRmToolUserMapping [get]
export var findRmToolUserMapping = function findRmToolUserMapping(params) {
  return service({
    url: "/rmToolUserMapping/findRmToolUserMapping",
    method: 'get',
    params: params
  });
};

// @Tags RmToolUserMapping
// @Summary 分页获取RmToolUserMapping列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取RmToolUserMapping列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmToolUserMapping/getRmToolUserMappingList [get]
export var getRmToolUserMappingList = function getRmToolUserMappingList(params) {
  return service({
    url: "/rmToolUserMapping/getRmToolUserMappingList",
    method: 'get',
    params: params
  });
};